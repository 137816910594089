<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="6">
        <b-card
          :title="$t('Users.Create.title')"
        >
          <b-form @submit.prevent="onRegister">
            <b-row>
              <!-- email -->
              <b-col cols="12">
                <b-form-group
                  :label="$t('Users.Create.form.emailLabel')"
                  label-for="vi-email"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-email"
                      v-model="email"
                      type="email"
                      :placeholder="$t('Users.Create.form.emailPlaceholder')"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group :label="$t('Users.Create.form.roleLabel')">
                  <b-form-radio-group
                    v-model="selected"
                    :options="options"
                    name="radios-stacked"
                    stacked
                  />
                </b-form-group>
              </b-col>

              <!-- reset and submit -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  class="mr-1"
                >
                  {{ $t('Users.Create.form.buttonText') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BInputGroupPrepend, BCard, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    BForm,
    BButton,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      selected: 1,
      options: [
        { text: this.$i18n.t('Users.roles.client'), value: 1 },
        { text: this.$i18n.t('Users.roles.overseer'), value: 4 },
        { text: this.$i18n.t('Users.roles.lawyer'), value: 5 },
        // { text: this.$i18n.t('Users.roles.external'), value: 0 },
        { text: this.$i18n.t('Users.roles.administrator'), value: 2 },
      ],
    }
  },
  sockets: {
    reply(data) {
      console.log(data)
    },
  },
  methods: {
    onRegister() {
      this.$http.post('/jwt2/register', {
        email: this.email,
        role: this.selected,
      })
        .then(response => {
          this.showActivationTicket(response.data.activationToken)
        })
        .catch(error => {
          console.log('There was a problem:', error)
          this.showRegistrationFailed()
        })
    },
    showActivationTicket(activationTicket) {
      this.$swal({
        title: this.$i18n.t('SwAl.titles.userRegistered'),
        html: this.$i18n.t('SwAl.htmls.activationTicket', { activationTicket }),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        console.log(result)
        this.clearForm()
      })
    },
    showRegistrationFailed() {
      this.$swal({
        title: this.$i18n.t('SwAl.titles.error'),
        html: this.$i18n.t('SwAl.htmls.couldntRegisterUser', { email: this.email }),
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        console.log(result)
        this.clearForm()
      })
    },
    clearForm() {
      this.first_name = ''
      this.last_name = ''
      this.email = ''
      this.selected = 1 // It starts with selected=1, Why change it?
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
